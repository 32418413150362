import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { ThemeContext } from "../../theme/ThemeProvider";
import { FaUser, FaUsers, FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const OnlineClass = () => {
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  const { isDarkMode } = themeContext;

  const navigate = useNavigate();

  return (
    <div
      className={`items-center justify-center ${
        isDarkMode ? "bg-globalBg text-gray-300" : "bg-[#FFFF] text-black"
      } `}
    >
      <div className={`container m-auto px-4 py-8 `}>
        {/* Header Section */}
        <div className="text-center mb-16">
          {/* <h1 className="text-3xl md:text-5xl font-bold text-blue-500 mb-3"> */}
          <h1 className="text-2xl left-0 md:text-3xl lg:text-[41px] font-extrabold tracking-tight mb-4 justify-start text-blue-500">
            Online Educational Center
          </h1>
          <div
            className={` mb-5 inline-block p-2 rounded-full ${
              isDarkMode ? "bg-blue-500" : "bg-blue-100"
            }`}
          >
            <p
              className={`text-sm font-semibold ${
                isDarkMode ? "text-white" : "text-blue-800"
              }`}
            >
              Certified Islamic Scholar
            </p>
          </div>
          {/* <p className="text-lg max-w-3xl mx-auto"> */}
          <p className="text-base md:text-lg text-[#868E96] dark:text-gray-400">
            We are giving the opportunity for you and your children to learn
            Quran and Urdu online from our expert teachers.
          </p>
        </div>

        {/* Plans Grid */}
        <div className="grid md:grid-cols-2 sm:grid-cols-2 max-w-xl gap-2 mx-auto">
          {/* Group Plan */}
          <div
            className={` max-w-2xl  rounded-2xl shadow-lg p-8 hover:shadow-xl transition-shadow 
                    ${
                      isDarkMode
                        ? "bg-[#2e2e2e]"
                        : "bg-gradient-to-b from-blue-50 to-white"
                    }`}
          >
            <div className="flex flex-col ">
              <div
                className={`p-3 w-min rounded-full mb-4 ${
                  isDarkMode ? "bg-blue-500 " : "bg-blue-100"
                }`}
              >
                <FaUsers
                  className={`w-6 h-6  ${
                    isDarkMode ? "text-white " : "text-blue-600"
                  }`}
                />
              </div>
              <h2 className="text-2xl font-bold text-blue-600 mb-4">Group</h2>
              <p className="mb-6">Learn Together, Stay Motivated</p>
              <div className="text-3xl font-bold mb-4">₹500/Month</div>
              <ul className="space-y-2 mb-3">
                <li className="flex gap-x-3">
                  <svg
                    className="h-6 w-5 flex-none text-blue-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Group Learning
                </li>
                <li className="flex gap-x-3">
                  <svg
                    className="h-6 w-5 flex-none text-blue-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Fixed Timings
                </li>
                <li className="flex gap-x-3">
                  <svg
                    className="h-6 w-5 flex-none text-blue-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Peer Interaction
                </li>
                <li className="flex gap-x-3">
                  <svg
                    className="h-6 w-5 flex-none text-blue-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Collaborative Environment
                </li>
              </ul>
            </div>
          </div>

          {/* Special Plan */}
          <div className="bg-gradient-to-br max-w-2xl from-blue-500 to-blue-700 rounded-2xl shadow-lg p-8 hover:shadow-xl transition-shadow">
            <div className="flex flex-col ">
              <div className="p-3 bg-white w-min rounded-full mb-4">
                <FaStar className="w-6 h-6 text-blue-600" />
              </div>
              <h2 className="text-2xl font-bold text-white mb-4">Special</h2>
              <p className="text-emerald-50 mb-6">
                Unlimited Access to Courses
              </p>
              <div className="text-3xl font-bold text-white mb-4">Free </div>
              <ul className="text-emerald-50 space-y-2 mb-3">
                <li className="flex gap-x-3">
                  <svg
                    className="h-6 w-5 flex-none text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Unlimited Access
                </li>
                <li className="flex gap-x-3">
                  <svg
                    className="h-6 w-5 flex-none text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Interactive Sessions
                </li>
                <li className="flex gap-x-3">
                  <svg
                    className="h-6 w-5 flex-none text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Personalized Feedback
                </li>
                <li className="flex gap-x-3">
                  <svg
                    className="h-6 w-5 flex-none text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Group Learning Benefits
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mt-6 flex justify-center items-center gap-4  ">
          {/* <button
            type="button"
            onClick={() => navigate("/jrm/online/registration")}
            className={`px-7 py-3 text-sm font-semibold md:text-lg rounded-md bg-blue-600 ${
              isDarkMode
                ? "text-[#ffff] hover:bg-blue-700"
                : " border-gray-200  text-[#ffff]"
            }`}
          >
            Register Now
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default OnlineClass;
