import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ThemeProvider from "./Components/theme/ThemeProvider";
import { Provider } from "react-redux";
import store from "./Components/hooks/Redux/store";
import { Toaster } from "react-hot-toast";
import { DonationProvider } from "@11solutionspvtltd/eleven-solutions-trust-website";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <DonationProvider>
      <ThemeProvider>
        <Provider store={store}>
          <App />
          <Toaster />
        </Provider>
      </ThemeProvider>
    </DonationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
