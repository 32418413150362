import React, { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { clearProfileData } from "@11solutionspvtltd/eleven-solutions-common-website/dist/components/redux/slices/userSlice";
import { useDispatch } from "react-redux";
import TFLogo from "../../Images/TFLogo.png";
import {
  FaBook,
  FaUser,
  FaCog,
  FaList,
  FaChartBar,
  FaBriefcase,
  FaRegFileAlt,
  FaSignOutAlt,
} from "react-icons/fa";
import {
  MdCategory,
  MdOutlineLibraryBooks,
  MdOutlineDashboard,
  MdHomeFilled,
  MdMenuBook,
  MdAppRegistration,
} from "react-icons/md";
import { FaClipboardList } from "react-icons/fa";
import { VscTypeHierarchySub } from "react-icons/vsc";

import { AdminSideBar } from "@11solutionspvtltd/eleven-solutions-common-website/dist/index";
import { ThemeContext } from "../theme/ThemeProvider";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  const { isDarkMode } = themeContext;

  const handleLogout = () => {
    dispatch(clearProfileData());
  };

  const menuGroups = [
    {
      heading: "ANALYTICS",
      items: [
        {
          label: "Dashboard",
          icon: <MdOutlineDashboard />,
          onClick: () => navigate("/admin/dashboard"),
        },
        {
          label: "Performance",
          icon: <FaChartBar />,
          onClick: () => navigate("#"),
        },
      ],
    },
    {
      heading: "LIBRARY",
      items: [
        {
          label: "Expense ",
          icon: <MdOutlineLibraryBooks />,
          onClick: () => navigate("/admin/Expense"),
        },
        {
          label: "Donation ",
          icon: <FaBook />,
          onClick: () => navigate("/admin/DonationDetails"),
        },
        {
          label: "Donation Type Purpose",
          icon: <FaList />,
          onClick: () => navigate("/admin/DonationTypePurpose"),
        },
        {
          label: "Bank ",
          icon: <FaUser />,
          onClick: () => navigate("/admin/BankDetails"),
        },
        {
          label: "Balance Transfer",
          icon: <MdCategory />,
          onClick: () => navigate("/admin/BalanceTransfer"),
        },
        {
          label: "Box",
          icon: <FaBriefcase />,
          onClick: () => navigate("/admin/box"),
        },
      ],
    },
    {
      heading: "EDUACTION",
      items: [
        {
          label: "Education Type",
          icon: <MdCategory />,
          onClick: () => navigate("/admin/EducationType"),
        },
        {
          label: "Course Type",
          icon: <VscTypeHierarchySub />,
          onClick: () => navigate("/admin/CourseType"),
        },
        {
          label: "Course",
          icon: <FaClipboardList />,
          onClick: () => navigate("/admin/Course"),
        },
        {
          label: "Course Offering",
          icon: <MdMenuBook />,
          onClick: () => navigate("/admin/CourseOffering"),
        },
        {
          label: "Registration",
          icon: <MdAppRegistration />,
          onClick: () => navigate("/admin/Registration"),
        },
      ],
    },
    {
      heading: "CUSTOMIZATION",
      items: [
        {
          label: "Users",
          icon: <FaUser />,
          onClick: () => navigate("/admin/users"),
        },
        {
          label: "Taxonomies",
          icon: <FaCog />,
          onClick: () => navigate("/admin/taxinomies"),
        },
        {
          label: "Templates",
          icon: <FaRegFileAlt />,
          onClick: () => navigate("/admin/template"),
        },
        {
          label: "Home",
          icon: <MdHomeFilled />,
          onClick: () => navigate("/home"),
        },
        {
          label: "Logout",
          icon: <FaSignOutAlt />,
          onClick: () => handleLogout(),
        },
      ],
    },
  ];

  return (
    <div className={`${isDarkMode ? "bg-globalBg" : "bg-[#ffff]"}`}>
      <div className=" h-screen shadow-md  flex flex-row w-full md:gap-x-2 border-r">
        <AdminSideBar menuGroups={menuGroups} logo={TFLogo} />
        <div
          className={`w-full h-full overflow-y-auto  pr-8 ${
            isDarkMode ? "bg-globalBg" : "bg-[#ffff]"
          }`}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
